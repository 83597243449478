import { TranslationFunction } from '@zooz/react-localize'
import { createSelector } from 'reselect'

import { PortalState } from '../../../../redux/store'
import validatePassword from '../../../../helpers/passwordValidation'
import language from '../language'

export const selectPasswordExpiryFields = (state: PortalState) => state.passwordExpiry.fields
export const selectPasswordExpiryErrors = (state: PortalState) => state.passwordExpiry.errors
export const selectIsChangingPassword = (state: PortalState) => state.passwordExpiry.isChangingPassword

export const selectPasswordExpiryFocused = (state: PortalState) => state.passwordExpiry.focusedFields
export const selectPasswordExpiryTouched = (state: PortalState) => state.passwordExpiry.touched

const selectUserEmail = (state: PortalState) => state.Auth.authReducer.email

export const selectPasswordExpiryRequiredFields = createSelector(
  [selectPasswordExpiryFields],
  (fields) => {
    const required: { [key: string]: (t: TranslationFunction) => string } = {}
    const { currentPassword, newPassword, confirmPassword } = fields

    if (!currentPassword) {
      required.currentPassword = language.errorMessages.passwordRequired
    }
    if (!newPassword) {
      required.newPassword = language.errorMessages.passwordRequired
    }
    if (!confirmPassword && newPassword) {
      required.confirmPassword = language.errorMessages.confirmNewPassword
    }

    return required
  }
)

export const selectPasswordExpiryInvalidFields = createSelector(
  [selectPasswordExpiryFields, selectUserEmail],
  (fields, userEmail) => {
    const invalid: { [key: string]: (t: TranslationFunction) => string } = {}
    const { newPassword, confirmPassword, currentPassword } = fields

    if (newPassword) {
      const passwordValidationResult = validatePassword(newPassword, {
        email: userEmail,
        currentPassword
      })
      if (!passwordValidationResult.valid && passwordValidationResult.message) {
        invalid.newPassword = passwordValidationResult.message
      }
    }

    if (confirmPassword && newPassword && confirmPassword !== newPassword) {
      invalid.confirmPassword = language.errorMessages.passwordsDoNotMatch
    }

    return invalid
  }
)
