import produce, { Draft } from 'immer'

import * as types from './types'

export interface PasswordExpiryState {
  fields: {
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }
  focusedFields: string[]
  touched: string[]
  errors: {
    currentPassword?: string
    newPassword?: string
    confirmPassword?: string
    general?: string
  }
  isChangingPassword: boolean
}

const initialState: PasswordExpiryState = {
  fields: {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  },
  focusedFields: [],
  touched: [],
  errors: {},
  isChangingPassword: false
}

const passwordExpiryReducer = produce((state: Draft<PasswordExpiryState>, action: any) => {
  switch (action.type) {
    case types.PASSWORD_EXPIRY_INPUT_CHANGE: {
      const { field, value } = action.payload as {
        field: 'currentPassword' | 'newPassword' | 'confirmPassword';
        value: string;
      }
      state.fields[field] = value
      break
    }
    case types.PASSWORD_EXPIRY_FOCUS_CHANGE: {
      const { field, focused } = action.payload
      if (focused) {
        if (!state.touched.includes(field)) {
          state.touched.push(field)
        }
        if (!state.focusedFields.includes(field)) {
          state.focusedFields.push(field)
        }
      } else {
        state.focusedFields = state.focusedFields.filter(f => f !== field)
      }
      break
    }
    case types.CHANGE_EXPIRED_PASSWORD_REQUEST: {
      state.isChangingPassword = true
      state.errors = {}
      break
    }
    case types.CHANGE_EXPIRED_PASSWORD_SUCCESS: {
      Object.assign(state, initialState)
      break
    }
    case types.CHANGE_EXPIRED_PASSWORD_FAILURE: {
      state.isChangingPassword = false
      state.errors.general = action.payload
      break
    }
    case types.SET_PASSWORD_EXPIRY_FIELD_ERRORS: {
      const errors = action.payload
      state.errors = {
        ...state.errors,
        ...errors
      }
      state.isChangingPassword = false
      break
    }
    case types.CLEAR_PASSWORD_EXPIRY_FIELD_ERROR: {
      const field = action.payload as keyof PasswordExpiryState['errors']
      delete state.errors[field]
      break
    }
    case types.CLEAR_PASSWORD_EXPIRY_STATE: {
      Object.assign(state, initialState)
      break
    }
  }
}, initialState)

export default passwordExpiryReducer
