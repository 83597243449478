import { TranslationFunction } from '@zooz/react-localize'
import {
  user_profile as userProfileTranslations,
  global as globalTranslations,
  password_expiry as passwordExpiryTranslations,
  reset_password as resetPasswordTranslations
} from '@zooz/translation-values'

export default {
  titleExpired: (t: TranslationFunction): string => t(passwordExpiryTranslations.main_title_passwordExpired.key, {
    fallback: passwordExpiryTranslations.main_title_passwordExpired.fallback
  }),

  titleWillExpireSoon: (t: TranslationFunction): string => t(
    passwordExpiryTranslations.main_title_passwordExpiresSoon.key, {
      fallback: passwordExpiryTranslations.main_title_passwordExpiresSoon.fallback
    }
  ),

  titleWillExpireTomorrow: (t: TranslationFunction): string => t(
    passwordExpiryTranslations.main_title_passwordExpiresTomorrow.key, {
      fallback: passwordExpiryTranslations.main_title_passwordExpiresTomorrow.fallback
    }
  ),

  titleWillExpireDays: (t: TranslationFunction, days: number): string => t(
    passwordExpiryTranslations.main_title_passwordWillExpireDays.key, {
      fallback: passwordExpiryTranslations.main_title_passwordWillExpireDays.fallback,
      days
    }
  ),

  subtitle: (t: TranslationFunction): string => t(
    passwordExpiryTranslations.main_subtitle_passwordRequirements.key, {
      fallback: passwordExpiryTranslations.main_subtitle_passwordRequirements.fallback
    }
  ),

  remindMeLater: (t: TranslationFunction): string => t(
    passwordExpiryTranslations.main_link_remindMeLater.key, {
      fallback: passwordExpiryTranslations.main_link_remindMeLater.fallback
    }
  ),

  passwordChangedSuccessfully: (t: TranslationFunction): string => t(
    resetPasswordTranslations.resetForm_msgSuccess_passwordChanged.key, {
      fallback: resetPasswordTranslations.resetForm_msgSuccess_passwordChanged.fallback
    }
  ),

  placeholders: {
    currentPassword: (t: TranslationFunction): string => t(userProfileTranslations.main_lbl_currentPassword.key, {
      fallback: userProfileTranslations.main_lbl_currentPassword.fallback
    }),
    newPassword: (t: TranslationFunction): string => t(userProfileTranslations.main_lbl_newPassword.key, {
      fallback: userProfileTranslations.main_lbl_newPassword.fallback
    }),
    confirmNewPassword: (t: TranslationFunction): string => t(userProfileTranslations.main_lbl_repeatPassword.key, {
      fallback: userProfileTranslations.main_lbl_repeatPassword.fallback
    })
  },

  buttonText: (t: TranslationFunction): string => t(
    passwordExpiryTranslations.main_btn_changePassword.key, {
      fallback: passwordExpiryTranslations.main_btn_changePassword.fallback
    }
  ),

  errorMessages: {
    passwordRequired: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_errors_passwordRequired.key, {
        fallback: globalTranslations.sharedText_errors_passwordRequired.fallback
      }
    ),
    confirmNewPassword: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_errors_confirmPassword.key, {
        fallback: globalTranslations.sharedText_errors_confirmPassword.fallback
      }
    ),
    passwordsDoNotMatch: (t: TranslationFunction): string => t(
      globalTranslations.sharedText_errors_passwordMatch.key, {
        fallback: globalTranslations.sharedText_errors_passwordMatch.fallback
      }
    ),
    defaultError: (t: TranslationFunction): string => t(globalTranslations.sharedText_errors_defaultError.key, {
      fallback: globalTranslations.sharedText_errors_defaultError.fallback
    })
  }
}
