import { TranslationFunction } from '@zooz/react-localize'
import { createSelector } from 'reselect'
import validator from 'validator'

import errors from '../../../../constants/Errors'
import validatePassword from '../../../../helpers/passwordValidation'
import { ResetReducer, RequestResetReducer } from './reducers'

interface State {
  resetPassword: {
    reset: ResetReducer,
    requestReset: RequestResetReducer
  }
}

export const requestEmail = (state: State) => state.resetPassword.requestReset.email
export const requestFocused = (state: State) => state.resetPassword.requestReset.focused
export const requestError = (state: State) => state.resetPassword.requestReset.error
export const requestTraveresedFields = (state: State) => state.resetPassword.requestReset.traveresed
export const isRequesting = (state: State) => state.resetPassword.requestReset.loading

export interface RequestInvalidFields {
  email?: (t: TranslationFunction) => string
}

export const requestInvalidFields = createSelector([requestEmail],
  (email) => {
    const fields: RequestInvalidFields = {}
    if (email && !validator.isEmail(email)) {
      fields.email = errors.email.invalid
    }
    return fields
  })

export const requestRequiredFields = createSelector([requestEmail],
  (email) => {
    const fields: RequestInvalidFields = {}
    if (!email) {
      fields.email = errors.email.required
    }
    return fields
  })

export const resetFields = (state: State) => ({
  email: state.resetPassword.reset.email,
  password: state.resetPassword.reset.password,
  passwordAgain: state.resetPassword.reset.passwordAgain
})

export const resetTraveresedFields = (state: State) => state.resetPassword.reset.traveresed
export const resetError = (state: State) => state.resetPassword.reset.error
export const resetIsReseting = (state: State) => state.resetPassword.reset.loading

export interface ResetInvalidFields {
  email?: (t: TranslationFunction) => string,
  password?: (t: TranslationFunction) => string,
  passwordAgain?: (t: TranslationFunction) => string
}

export const resetInvalidFields = createSelector([resetFields],
  ({ email, password, passwordAgain }) => {
    const fields: ResetInvalidFields = {}
    if (email && !validator.isEmail(email)) {
      fields.email = errors.email.invalid
    }
    if (password) {
      const validPasswordMessage = validatePassword(password, { email }).message
      if (validPasswordMessage) {
        fields.password = validPasswordMessage
      }
    }
    if (passwordAgain && password !== passwordAgain) {
      fields.passwordAgain = errors.confirmPassword.invalid
    }
    return fields
  })

export const resetRequiredFields = createSelector([resetFields],
  ({ email, password, passwordAgain }) => {
    const fields: ResetInvalidFields = {}
    if (!email) {
      fields.email = errors.email.required
    }
    if (!password) {
      fields.password = errors.password.required
    }
    if (!passwordAgain) {
      fields.passwordAgain = errors.confirmPassword.required
    }
    return fields
  })

export const resetFocused = (state: State) => state.resetPassword.reset.focused
