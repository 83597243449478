const Config = {
  apiUrl: process.env.REACT_APP_API_URL,
  requestTimeout: process.env.REQUEST_TIMEOUT || 58000,
  supportTools: process.env.SUPPORT_TOOLS,
  baseRouteName: process.env.BASE_ROUTE_NAME,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentrySamplingRate: process.env.REACT_APP_SENTRY_SAMPLING_RATE || 0.2,
  demo: {
    user: process.env.ZOOZ_DEMO_USER,
    account: process.env.ZOOZ_DEMO_ACCOUNT
  },
  zoozEnv: process.env.ZOOZ_ENV,
  apps: {
    virtualTerminal: process.env.REACT_APP_VIRTUAL_TERMINAL,
    services: process.env.REACT_APP_SERVICES,
    reports: process.env.REACT_APP_REPORTS,
    webhooks: process.env.REACT_APP_WEBHOOKS,
    paymentsSearch: process.env.REACT_APP_PAYMENTS_SEARCH,
    paymentsInsights: process.env.REACT_APP_PAYMENTS_INSIGHTS,
    businessUnits: process.env.REACT_APP_BUSINESS_UNITS,
    transactionsApproval: process.env.REACT_APP_TRANSACTIONS_APPROVAL,
    instantRetry: process.env.REACT_APP_INSTANT_RETRY,
    decisionEngine: process.env.REACT_APP_DECISION_ENGINE,
    accountManagement: process.env.REACT_APP_ACCOUNT_MANAGEMENT,
    balancesManagement: process.env.REACT_APP_BALANCES_MANAGEMENT,
    payoutsManagement: process.env.REACT_APP_PAYOUTS_MANAGEMENT,
    treasuryAnalytics: process.env.REACT_APP_TREASURY_ANALYTICS
  }
}
export default Config
