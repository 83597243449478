import permissions from '../constants/permissions'
import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'
import { combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'

const microAppUrl = envConfig.apps.paymentsInsights

const App: MicroApp = {
  name: 'payments_insights',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: (): boolean => {
    const paymentsInsightsPermission = permissions.analytics.paymentsInsights()
    const permissionsByPrefix = PermissionsUtil.findPermissionsByPrefix(
      paymentsInsightsPermission, PermissionsUtil.masqueraderRoles
    )
    return permissionsByPrefix.length > 0
  },
  paths: combinePathsWithEnv([
    Locations.paymentsInsights
  ])
}

export default App
