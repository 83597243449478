import { takeEvery, call, put, select } from 'redux-saga/effects'

import * as types from './types'
import {
  changeExpiredPasswordSuccess,
  changeExpiredPasswordFailure, setPasswordExpiryFieldErrors
} from './actions'
import UsersApi from '../../../../api/UsersApi'
import { selectPasswordExpiryFields } from './selectors'
import messageDispatcher from '../../../Messages/redux/messageDispatcher'
import { HttpError } from '../../../../helpers/httpClient'
import authActions from '../../../../redux/auth/actions'
import localize from '../../../Localize/localize'
import errors from '../../../../constants/Errors'
import Language from '../language'

function * changeExpiredPasswordSaga (): Generator<any, void, any> {
  try {
    const fields = yield select(selectPasswordExpiryFields)
    const { currentPassword, newPassword } = fields
    // @ts-ignore: ts fails to set types correctly
    yield call([UsersApi, UsersApi.updatePassword], { currentPassword, newPassword })
    yield put(changeExpiredPasswordSuccess())
    yield put(authActions.clearPasswordExpiryInfo())

    const userEmail = yield select(state => state.Auth.authReducer.email)
    yield put(authActions.loginUser({ email: userEmail, password: newPassword, from: '/' }))
    messageDispatcher.addSuccess(Language.passwordChangedSuccessfully(localize.translate))
  } catch (err) {
    const error = err as HttpError<{ more_info?: string }>
    const { response } = error
    if (response && response.data?.more_info === "Provided password does not match user's password.") {
      yield put(setPasswordExpiryFieldErrors({
        currentPassword: errors.password.currentPasswordIncorrect(localize.translate)
      }))
    } else if (response && response.data?.more_info === 'New password cannot be the same as the current password.') {
      yield put(setPasswordExpiryFieldErrors({
        currentPassword: errors.password.identicalToLast(localize.translate)
      }))
    } else {
      const errorMessage = Language.errorMessages.defaultError(localize.translate)
      yield put(changeExpiredPasswordFailure(errorMessage))
      messageDispatcher.addError(errorMessage)
    }
  }
}

export default function * passwordExpirySagas () {
  yield takeEvery(types.CHANGE_EXPIRED_PASSWORD_REQUEST, changeExpiredPasswordSaga)
}
