import * as types from './types'

interface Action {
  type: types.AuthAction
}

interface ActionIsLoggingIn extends Action {
  status: boolean
}

interface ActionIsJoiningUser extends Action {
  status?: boolean
}

interface ActionLoginUser extends Action {
  payload: { email: string, password: string, from: string }
}

interface LoginSuccessPayload {
  accountId: string,
  sessionToken: string,
  merchantIds: string[],
  userId: string,
  email: string,
  passwordChangeRequired?: boolean,
  passwordExpiresAt?: number,
}

interface ActionLoginSuccess extends Action {
  payload: LoginSuccessPayload
}

interface ActionLoginError extends Action {
  payload: string
}

interface ActionRegisterError extends Action {
  payload: string
}

interface JoinUserPayload {
  email: string,
  token: string,
  accountId: string,
  password: string,
  from: string
}

interface ActionJoinUser extends Action {
  payload: JoinUserPayload
}

interface ActionUnauthorized extends Action {
  code?: number
}

interface ActionIsUnattachedUser extends Action {
  payload: boolean;
}

export interface CheckSSOAction extends Action {
  payload: {
    email: string;
  };
}

interface CheckSSOEndAction extends Action {
  payload: {
    isSSO: boolean;
    isPasswordSupport: boolean;
  }
}

export interface CancelSSOCheckAction extends Action {}

export interface SSORedirectAction extends Action {
  payload: {
    email: string;
    redirectPath: string;
  };
}

export interface LoginUserSSOAction extends Action{
  payload: {
    sessionToken: string;
    from: string;
  };
}

export interface SSOUserLoggedInAction extends Action {
  payload: {
    email: string;
    userId: string;
    sessionToken: string;
    accountId: string;
    from: string;
  };
}

interface AuthActions {
  clearSession: () => Action;
  isLoggingIn: (status: boolean) => ActionIsLoggingIn;
  isUnattachedUser: (status: boolean) => ActionIsUnattachedUser;
  checkSSOUser: (email: string) => CheckSSOAction,
  checkSSOEnd: (isSSO: boolean, isPasswordSupport: boolean) => CheckSSOEndAction
  cancelSSOCheck: () => CancelSSOCheckAction;
  ssoRedirect: (email: string, redirectPath: string) => SSORedirectAction;
  loginUserSSO: (loginDetails: { sessionToken: string, from: string }) => LoginUserSSOAction;
  ssoUserLoggedIn: (
    email: string,
    userId: string,
    sessionToken: string,
    accountId: string,
    from: string
  ) => SSOUserLoggedInAction;
  isJoiningUser: (status: boolean) => ActionIsJoiningUser;
  loginUser: (loginDetails: { email: string, password: string, from: string }) => ActionLoginUser;
  loginSuccess: (sessionInfo: LoginSuccessPayload) => ActionLoginSuccess;
  loginPageError: (errorMsg: string) => ActionLoginError;
  registerPageError: (errorMsg: string) => ActionRegisterError;
  joinUser: (payload: JoinUserPayload) => ActionJoinUser;
  joinExistingUser: (payload: JoinUserPayload) => ActionJoinUser;
  authorized: () => Action;
  unauthorized: (code?: number) => ActionUnauthorized;
  clearPasswordExpiryInfo: () => Action;
}

const authActions: AuthActions = {
  clearSession: (): Action => ({
    type: types.CLEAR_SESSION
  }),
  isLoggingIn: (status: boolean): ActionIsLoggingIn => ({
    type: types.IS_LOGGING_IN,
    status
  }),
  checkSSOUser: (email: string): CheckSSOAction => ({
    type: types.CHECK_SSO,
    payload: { email }
  }),
  checkSSOEnd: (isSSO: boolean, isPasswordSupport: boolean): CheckSSOEndAction => ({
    type: types.CHECK_SSO_END,
    payload: {
      isSSO,
      isPasswordSupport
    }
  }),
  cancelSSOCheck: () => ({ type: types.CANCEL_CHECK_SSO }),
  ssoRedirect: (email, redirectPath) => ({
    type: types.SSO_REDIRECT,
    payload: {
      email,
      redirectPath
    }
  }),
  isJoiningUser: (status: boolean): ActionIsJoiningUser => ({
    type: types.IS_JOINING_USER,
    status
  }),
  loginUser: ({ email, password, from }: { email: string; password: string; from: string }): ActionLoginUser => ({
    type: types.LOGIN_USER,
    payload: { email, password, from }
  }),
  loginUserSSO: ({ sessionToken, from }: { sessionToken: string; from: string }): LoginUserSSOAction => ({
    type: types.SSO_LOGIN_USER,
    payload: {
      sessionToken,
      from
    }
  }),
  ssoUserLoggedIn: (
    email: string,
    userId: string,
    sessionToken: string,
    accountId: string,
    from: string
  ): SSOUserLoggedInAction => ({
    type: types.SSO_USER_LOGGED_IN,
    payload: {
      email,
      userId,
      sessionToken,
      accountId,
      from
    }
  }),
  loginSuccess: (sessionInfo: LoginSuccessPayload): ActionLoginSuccess => ({
    type: types.LOGIN_SUCCESS,
    payload: sessionInfo
  }),
  loginPageError: (errorMsg: string): ActionLoginError => ({
    type: types.LOGIN_PAGE_ERROR,
    payload: errorMsg
  }),
  registerPageError: (errorMsg: string): ActionRegisterError => ({
    type: types.REGISTER_PAGE_ERROR,
    payload: errorMsg
  }),
  joinUser: (payload: JoinUserPayload): ActionJoinUser => ({
    type: types.JOIN_USER,
    payload
  }),
  joinExistingUser: (payload: JoinUserPayload): ActionJoinUser => ({
    type: types.JOIN_EXISTING_USER,
    payload
  }),
  authorized: (): Action => ({
    type: types.AUTHORIZED
  }),
  unauthorized: (code?: number): ActionUnauthorized => ({
    type: types.UNAUTHORIZED,
    code
  }),
  isUnattachedUser: (status: boolean): ActionIsUnattachedUser => ({
    type: types.IS_UNATTACHED_USER,
    payload: status
  }),
  clearPasswordExpiryInfo: (): Action => ({
    type: types.CLEAR_PASSWORD_EXPIRY_INFO
  })
}

export default authActions
