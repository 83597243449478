import { createSelector } from 'reselect'

import { getAccountId } from '../account/selectors'
import { PortalState } from '../store'
import { AuthState } from './reducer'

export const isLoggingIn = (state: PortalState): boolean => state.Auth.authStatus.isLoggingIn

export const getIsUnattachedUser = (state: PortalState): boolean => state.Auth.authStatus.isUnattachedUser

export const isJoining = (state: PortalState): boolean => state.Auth.authStatus.isJoining

export const getJoinError = (state: PortalState): string => state.Auth.authStatus.registrationError

export const getIsSSO = (state: PortalState): boolean => state.Auth.authStatus.isSSO

export const getIsCheckingSSO = (state: PortalState): boolean => state.Auth.authStatus.isCheckingSSO

export const getIsPasswordSupport = (state: PortalState): boolean => state.Auth.authStatus.isPasswordSupport

export interface CurrentUser {
  get: () => AuthState,
  email: string,
  sessionToken: string,
  userId: string,
  accountId: string,
  merchantIds: string[],
  initializedByLogin: boolean,
  passwordChangeRequired: boolean
}

export const currentUser = (state: PortalState): CurrentUser => {
  const user = state.Auth.authReducer
  return {
    get () {
      return user
    },
    email: user.email,
    sessionToken: user.sessionToken,
    userId: user.userId,
    accountId: user.accountId,
    merchantIds: user.merchantIds,
    initializedByLogin: user.initializedByLogin,
    passwordChangeRequired: user.passwordChangeRequired
  }
}

export const isMasqueraderUser = createSelector(
  currentUser,
  (user): boolean => user.merchantIds.includes('*')
)

export const isCurrentlyMasquerading = createSelector(
  [currentUser, getAccountId],
  (user, accountId): boolean => (
    accountId &&
    !user.merchantIds.includes(accountId) &&
    user.merchantIds.includes('*')
  )
)

export const isLoggedIn = createSelector(
  currentUser,
  (user): boolean => !!user.sessionToken
)

export const getCurrentUserAccountId = createSelector(
  currentUser,
  (user): string => user.accountId
)

export const getSessionToken = (state: PortalState): string => state.Auth.authReducer.sessionToken
