import React, { useEffect, useMemo, useRef } from 'react'
import { TranslationFunction, useLocalize } from '@zooz/react-localize'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Paragraph } from '@zooz/generic-ui-components'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { ButtonSubmit as Button, FormError, FormItem, InputField, Page } from '../shared'
import {
  changeExpiredPasswordRequest,
  clearPasswordExpiryFieldError,
  clearPasswordExpiryState,
  passwordExpiryFocusChange,
  passwordExpiryInputChange,
  setPasswordExpiryFieldErrors
} from './redux/actions'
import {
  selectIsChangingPassword,
  selectPasswordExpiryErrors,
  selectPasswordExpiryFields,
  selectPasswordExpiryFocused,
  selectPasswordExpiryTouched,
  selectPasswordExpiryRequiredFields,
  selectPasswordExpiryInvalidFields
} from './redux/selectors'
import { currentUser } from '../../../redux/auth/selectors'
import Locations from '../../../helpers/tools/locations'
import language from './language'
import passwordRequirements from '../shared/language/passwordRequirements'
import { withLoadTranslations } from '../../Localize/HOCs'
import { ScreenNames } from '../../Localize/screens'
import { getEnvironment } from '../../../redux/environment/selectors'
import authActions from '../../../redux/auth/actions'

import css from './PasswordExpiry.scss'

interface PasswordExpiryScreenProps extends RouteComponentProps {}

type fieldType = 'currentPassword' | 'newPassword' | 'confirmPassword'
const getCardTitle = (
  t: TranslationFunction,
  daysRemaining: number,
  passwordChangeRequired: boolean
): string => {
  if (daysRemaining < 0 || passwordChangeRequired) {
    return language.titleExpired(t)
  }
  if (daysRemaining === 0) {
    return language.titleWillExpireSoon(t)
  }
  if (daysRemaining === 1) {
    return language.titleWillExpireTomorrow(t)
  }
  return language.titleWillExpireDays(t, daysRemaining)
}

const PasswordExpiry: React.FC<PasswordExpiryScreenProps> = ({ history }) => {
  const { t } = useLocalize()
  const dispatch = useDispatch()
  const user = useSelector(currentUser)
  const env = useSelector(getEnvironment)
  const { passwordExpiresAt, passwordChangeRequired, initializedByLogin } = user.get()
  const fieldsData = useSelector(selectPasswordExpiryFields)
  const errors = useSelector(selectPasswordExpiryErrors)
  const isChangingPassword = useSelector(selectIsChangingPassword)
  const requiredFields = useSelector(selectPasswordExpiryRequiredFields)
  const invalidFields = useSelector(selectPasswordExpiryInvalidFields)
  const focusedFields = useSelector(selectPasswordExpiryFocused)
  const touchedFields = useSelector(selectPasswordExpiryTouched)

  const formRef = useRef<HTMLFormElement>(null)

  const daysRemaining = useMemo(() => {
    if (passwordExpiresAt && passwordExpiresAt > Date.now()) {
      return differenceInCalendarDays(passwordExpiresAt, Date.now())
    }
    return -1
  }, [passwordExpiresAt])

  const title = getCardTitle(t, daysRemaining, passwordChangeRequired)

  useEffect(() => {
    const currentPath = history.location.pathname
    const unblock = history.block((location, action) => {
      if (action === 'POP' && location.pathname !== currentPath) {
        return 'Are you sure you want to leave?'
      }
      return undefined
    })
    return () => {
      unblock()
      dispatch(clearPasswordExpiryState())
    }
  }, [dispatch, history])

  useEffect(() => {
    if (!initializedByLogin) {
      dispatch(authActions.unauthorized())
    }
  }, [dispatch, initializedByLogin])

  const getMessage = (field: fieldType): string => {
    if (errors[field]) { return errors[field] }
    if (!touchedFields?.includes(field)) { return '' }
    if (requiredFields[field]) { return requiredFields[field](t) }
    if (invalidFields[field]) { return invalidFields[field](t) }
    return ''
  }

  const isFocused = (field: string) => focusedFields.includes(field)
  const help = (field: fieldType): string => {
    const msg = getMessage(field)
    return msg && !isFocused(field) ? msg : ''
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    const finalErrors: { [key: string]: string } = {}
    Object.entries(requiredFields).forEach(([key, fn]) => {
      finalErrors[key] = fn(t)
    })
    Object.entries(invalidFields).forEach(([key, fn]) => {
      finalErrors[key] = fn(t)
    })

    if (Object.keys(finalErrors).length > 0) {
      dispatch(setPasswordExpiryFieldErrors(finalErrors))
      return
    }
    dispatch(changeExpiredPasswordRequest())
  }

  const handleRemindMeLater = () => {
    dispatch(authActions.clearPasswordExpiryInfo())
  }

  const instructionItems = passwordRequirements.instructionItems.map(item => item(t))

  const handleFieldChange = (field: fieldType, value: string) => {
    dispatch(passwordExpiryInputChange(field, value))
    if (errors[field]) {
      dispatch(clearPasswordExpiryFieldError(field))
    }
  }

  return (
    <Page
      className={css.passwordExpiryPage}
      title={title}
      subtitle={language.subtitle(t)}
      links={
        !passwordChangeRequired
          ? [
              {
                to: Locations.root(env),
                id: 'remind-me-later',
                text: language.remindMeLater(t),
                onClick: handleRemindMeLater
              }
            ]
          : []
      }
      dataTest='password-expiry-page'
    >
      <Paragraph className={css.passwordRequirementsTitle}>{passwordRequirements.title(t)}</Paragraph>
      <ul className={css.instructions}>
        {instructionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <form ref={formRef} onSubmit={handleSubmit} className={css.form}>
        <InputField
          value={fieldsData.currentPassword}
          onChange={event => handleFieldChange('currentPassword', event.target.value)}
          onBlur={() => dispatch(passwordExpiryFocusChange('currentPassword', false))}
          onFocus={() => dispatch(passwordExpiryFocusChange('currentPassword', true))}
          placeholder={language.placeholders.currentPassword(t)}
          id='current-password'
          type='password'
          error={help('currentPassword')}
          showTogglePassword
        />
        <InputField
          value={fieldsData.newPassword}
          onChange={event => handleFieldChange('newPassword', event.target.value)}
          onBlur={() => dispatch(passwordExpiryFocusChange('newPassword', false))}
          onFocus={() => dispatch(passwordExpiryFocusChange('newPassword', true))}
          placeholder={language.placeholders.newPassword(t)}
          id='new-password'
          type='password'
          error={help('newPassword')}
          showTogglePassword
        />
        <InputField
          value={fieldsData.confirmPassword}
          onChange={event => handleFieldChange('confirmPassword', event.target.value)}
          onBlur={() => dispatch(passwordExpiryFocusChange('confirmPassword', false))}
          onFocus={() => dispatch(passwordExpiryFocusChange('confirmPassword', true))}
          placeholder={language.placeholders.confirmNewPassword(t)}
          id='confirm-password'
          type='password'
          error={help('confirmPassword')}
          showTogglePassword
        />
        {errors.general && (
          <FormItem>
            <FormError isVisible={!!errors.general}>{errors.general}</FormError>
          </FormItem>
        )}
        <Button loading={isChangingPassword}>{language.buttonText(t)}</Button>
      </form>
    </Page>
  )
}

export default withLoadTranslations<PasswordExpiryScreenProps>({
  screenName: ScreenNames.PasswordExpiry
})(PasswordExpiry)
