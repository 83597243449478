import { differenceInCalendarDays } from 'date-fns'

interface PasswordExpiryInfo {
  passwordExpiresAt?: number;
  passwordChangeRequired?: boolean;
}

export const shouldRedirectToPasswordExpiry = (info: PasswordExpiryInfo): boolean => {
  const { passwordExpiresAt, passwordChangeRequired } = info

  if (passwordChangeRequired) {
    return true
  }

  if (passwordExpiresAt) {
    const expiresAtDate = new Date(passwordExpiresAt)
    const remainingDays = differenceInCalendarDays(expiresAtDate, new Date())
    return remainingDays <= 7
  }

  return false
}
