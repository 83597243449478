import * as types from './types'

export const passwordExpiryInputChange = (
  field: 'currentPassword' | 'newPassword' | 'confirmPassword',
  value: string
) => ({
  type: types.PASSWORD_EXPIRY_INPUT_CHANGE,
  payload: { field, value }
})

export const passwordExpiryFocusChange = (
  field: 'currentPassword' | 'newPassword' | 'confirmPassword',
  focused: boolean
) => ({
  type: types.PASSWORD_EXPIRY_FOCUS_CHANGE,
  payload: { field, focused }
})

export const changeExpiredPasswordRequest = () => ({
  type: types.CHANGE_EXPIRED_PASSWORD_REQUEST
})

export const changeExpiredPasswordSuccess = () => ({
  type: types.CHANGE_EXPIRED_PASSWORD_SUCCESS
})

export const changeExpiredPasswordFailure = (error: string) => ({
  type: types.CHANGE_EXPIRED_PASSWORD_FAILURE,
  payload: error
})

export const clearPasswordExpiryState = () => ({
  type: types.CLEAR_PASSWORD_EXPIRY_STATE
})

export const setPasswordExpiryFieldErrors = (errors: { [key: string]: string }) => ({
  type: types.SET_PASSWORD_EXPIRY_FIELD_ERRORS,
  payload: errors
})

export const clearPasswordExpiryFieldError = (field: string) => ({
  type: types.CLEAR_PASSWORD_EXPIRY_FIELD_ERROR,
  payload: field
})
