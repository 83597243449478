export const MicroAppToOperationsRoleIdentifier: Record<string, string> = {
  payouts_management: 'treasury',
  balances_management: 'treasury',
  reports: 'settlement',
  treasury_analytics: 'treasury'
}

export const OPERATORS_PROVIDERS_PREFIX = 'portal.operations.providers'
export const OPERATORS_PROVIDER_INDEX = 3
export const SAME_PASSWORD_ERROR = 'New password cannot be the same as the current password.'
