export const PASSWORD_EXPIRY_INPUT_CHANGE = 'PASSWORD_EXPIRY_INPUT_CHANGE'
export const PASSWORD_EXPIRY_FOCUS_CHANGE = 'PASSWORD_EXPIRY_FOCUS_CHANGE'
export const CHANGE_EXPIRED_PASSWORD_REQUEST = 'CHANGE_EXPIRED_PASSWORD_REQUEST'
export const CHANGE_EXPIRED_PASSWORD_SUCCESS = 'CHANGE_EXPIRED_PASSWORD_SUCCESS'
export const CHANGE_EXPIRED_PASSWORD_FAILURE = 'CHANGE_EXPIRED_PASSWORD_FAILURE'
export const CLEAR_PASSWORD_EXPIRY_STATE = 'CLEAR_PASSWORD_EXPIRY_STATE'
export const SET_PASSWORD_EXPIRY_FIELD_ERRORS = 'SET_PASSWORD_EXPIRY_FIELD_ERRORS'
export const CLEAR_PASSWORD_EXPIRY_FIELD_ERROR = 'CLEAR_PASSWORD_EXPIRY_FIELD_ERROR'
export const CLEAR_PASSWORD_EXPIRY_FLAG = 'CLEAR_PASSWORD_EXPIRY_FLAG'

export type PasswordExpiryAction =
  | typeof PASSWORD_EXPIRY_INPUT_CHANGE
  | typeof PASSWORD_EXPIRY_FOCUS_CHANGE
  | typeof CHANGE_EXPIRED_PASSWORD_REQUEST
  | typeof CHANGE_EXPIRED_PASSWORD_SUCCESS
  | typeof CHANGE_EXPIRED_PASSWORD_FAILURE
  | typeof CLEAR_PASSWORD_EXPIRY_STATE
  | typeof SET_PASSWORD_EXPIRY_FIELD_ERRORS
  | typeof CLEAR_PASSWORD_EXPIRY_FIELD_ERROR
  | typeof CLEAR_PASSWORD_EXPIRY_FLAG
