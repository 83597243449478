import { takeEvery, put, select, SelectEffect, PutEffect, ForkEffect } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

import { TEST } from '../../constants/environments'
import Locations from '../../helpers/tools/locations'
import { getLocation } from '../location/selectors'
import { setTestLive } from './actions'
import { isCrossEnv as isCrossEnvSelector } from './crossEnvSelectors'

function * setEnvironmentByURL (): IterableIterator<SelectEffect | PutEffect> {
  const location = getLocation(yield select())
  const isCrossEnv = yield select(isCrossEnvSelector)
  if (!Locations.isInNonProtectedArea(location.pathname) && !isCrossEnv) {
    const firstSection = location.pathname
      .split('/')
      .filter(Boolean)
      .at(0)

    const isLiveByURL = !(firstSection === TEST)
    yield put(setTestLive(isLiveByURL))
  }
}

export default function * environmentSaga (): IterableIterator<ForkEffect> {
  yield takeEvery(LOCATION_CHANGE, setEnvironmentByURL)
}
