import React from 'react'
import { Switch } from 'react-router-dom'

import * as ENVIRONMENTS from './constants/environments'
import App from './features/App/App'
import Locations from './helpers/tools/locations'
import PasswordExpiry from './features/UnProtected/PasswordExpiry'
import { PrivateRoute } from './routeUtils'

const RouterSignedIn: React.VFC = () => (
  <Switch>
    <PrivateRoute
      exact
      path={Locations.passwordExpiry()}
      component={PasswordExpiry}
    />
    <PrivateRoute
      path={Locations.root(ENVIRONMENTS.TEST)}
      component={App}
    />
    <PrivateRoute
      path={Locations.root(ENVIRONMENTS.LIVE)}
      component={App}
    />
  </Switch>
)

export default RouterSignedIn
