import React, { useEffect } from 'react'
import classNames from 'classnames'
import { H1, Paragraph, Card, Popover, PopoverPlacements } from '@zooz/generic-ui-components'
import { Link } from 'react-router-dom'

import { externalLocations } from '../../../../helpers/tools/locations/externalLocations'
import { LanguageSelection } from '../../../Localize/components'
import Messages from '../../../Messages'
import logo from '../payu-enterprise.svg'
import PasswordPolicyPopoverContent from './PasswordPolicyPopoverContent'
import localStorageAccessor from '../../../../helpers/localStorageAccessor/localStorageAccessor'

import css from './PageStructure.scss'

interface PageProps {
  id?: string,
  className?: string,
  title?: string,
  subtitle?: string,
  links?: {
    to?: string,
    id: string,
    text: string
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
  }[]
  dataTest?: string
}

const Page: React.FC<PageProps> = ({
  id,
  className,
  title,
  subtitle,
  children,
  links,
  dataTest
}) => {
  const [isPasswordPolicyPopupDismissed, setIsPasswordPolicyPopupDismissed] = React.useState(true)

  useEffect(() => {
    let isPasswordPolicyPopupDismissedInLocalStorage = false
    try {
      isPasswordPolicyPopupDismissedInLocalStorage = localStorageAccessor.dismissedPayUChangePassword
    } finally {
      setIsPasswordPolicyPopupDismissed(isPasswordPolicyPopupDismissedInLocalStorage)
    }
  }, [])

  return (
    <>
      <Messages />
      <div id={id} data-test={dataTest} className={classNames(css.page, className)}>

        <div className={css.brandingLogoSelector}>
          <Popover
            content={(
              <PasswordPolicyPopoverContent
                dismiss={() => {
                  setIsPasswordPolicyPopupDismissed(true)
                  localStorageAccessor.dismissedPayUChangePassword = true
                }}
              />
            )}
            offset={10}
            isOpened={!isPasswordPolicyPopupDismissed}
            placement={PopoverPlacements.BottomRight}
          >
            <a className={css.logo} target='_blank' rel='noopener noreferrer' href={externalLocations.zoozWebsite}>
              <img src={logo} height={95} />
            </a>
          </Popover>
        </div>

        <div className={css.backgroundLogo} />
        <Card type={Card.CARD_TYPES.DEFAULT} className={css.content}>
          <div className={css.header}>
            <H1 type={H1.TYPES.PAGE_TITLE} data-test='page-structure-title' className={css.title}>
              {title}
            </H1>
            {subtitle ? <Paragraph className={css.subtitle}>{subtitle}</Paragraph> : null}
          </div>
          <div className={css.form}>
            {children}
          </div>
          {
            links && links.length > 0 && (
              <div className={css.links}>
                {links.map(link => (
                  <Link
                    key={link.id}
                    id={link.id}
                    to={link.to}
                    onClick={link?.onClick}
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
            )
          }
        </Card>
        <div className={css.languageSelector}>
          <LanguageSelection />
        </div>
      </div>
    </>
  )
}

export default Page
