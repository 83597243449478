import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations } from '@zooz/translation-values'

import { PASSWORD_MIN_LENGTH } from '../../../../helpers/passwordValidation'

export default {
  title: (t: TranslationFunction): string => t(
    globalTranslations.sharedText_lbl_passwordRequirements.key, {
      fallback: globalTranslations.sharedText_lbl_passwordRequirements.fallback
    }
  ),
  instructionItems: [
    (t: TranslationFunction): string => t(
      globalTranslations.sharedText_passwordRequirements_minLength.key, {
        fallback: globalTranslations.sharedText_passwordRequirements_minLength.fallback,
        minLength: PASSWORD_MIN_LENGTH
      }
    ),
    (t: TranslationFunction): string => t(
      globalTranslations.sharedText_passwordRequirements_lettersNumberSpecial.key, {
        fallback: globalTranslations.sharedText_passwordRequirements_lettersNumberSpecial.fallback
      }
    ),
    (t: TranslationFunction): string => t(
      globalTranslations.sharedText_passwordRequirements_notIdenticalOrEmail.key, {
        fallback: globalTranslations.sharedText_passwordRequirements_notIdenticalOrEmail.fallback
      }
    ),
    (t: TranslationFunction): string => t(
      globalTranslations.sharedText_passwordRequirements_avoidPatterns.key, {
        fallback: globalTranslations.sharedText_passwordRequirements_avoidPatterns.fallback
      }
    )
  ]
}
