import produce, { Draft } from 'immer'
import { combineReducers } from 'redux'

import localStorageAccessor from '../../helpers/localStorageAccessor/localStorageAccessor'
import * as types from './types'

export interface AuthState {
  sessionToken: string;
  email: string;
  userId: string;
  accountId: string;
  merchantIds: string[];
  initializedByLogin: boolean;
  passwordChangeRequired?: boolean;
  passwordExpiresAt?: number;
}

export const initialState: AuthState = {
  sessionToken: null,
  email: null,
  userId: null,
  accountId: null,
  merchantIds: [],
  initializedByLogin: false,
  passwordChangeRequired: false,
  passwordExpiresAt: undefined
}

export const authReducer = produce((state: Draft<AuthState>, action) => {
  switch (action.type) {
    case types.AUTHORIZED: {
      state.sessionToken = localStorageAccessor.sessionToken || null
      state.email = localStorageAccessor.user.email || null
      state.userId = localStorageAccessor.user.id || null
      state.accountId = localStorageAccessor.activeMerchant || null
      state.merchantIds = localStorageAccessor.merchantIds || []
      break
    }
    case types.LOGIN_SUCCESS: {
      const {
        sessionToken,
        email,
        userId,
        accountId,
        merchantIds,
        passwordChangeRequired,
        passwordExpiresAt
      } = action.payload
      state.sessionToken = sessionToken
      state.email = email
      state.userId = userId
      state.accountId = accountId
      state.merchantIds = merchantIds
      state.initializedByLogin = true
      state.passwordChangeRequired = !!passwordChangeRequired
      state.passwordExpiresAt = passwordExpiresAt
      break
    }
    case types.CLEAR_PASSWORD_EXPIRY_INFO: {
      state.passwordExpiresAt = undefined
      state.passwordChangeRequired = false
      break
    }
  }
}, initialState)

export interface AuthStatusState {
  isUnattachedUser: boolean;
  isLoggingIn: boolean;
  isJoining: boolean;
  registrationError: string;
  isCheckingSSO: boolean;
  isSSO: boolean;
  isPasswordSupport: boolean;
}

const initStatusState: AuthStatusState = ({
  isUnattachedUser: false,
  isLoggingIn: false,
  registrationError: null,
  isJoining: undefined,
  isPasswordSupport: true,
  isCheckingSSO: false,
  isSSO: false
})

export const authStatus = produce((state: Draft<AuthStatusState>, action) => {
  switch (action.type) {
    case types.IS_UNATTACHED_USER:
      state.isUnattachedUser = action.payload
      break
    case types.IS_LOGGING_IN:
      state.isLoggingIn = action.status
      break
    case types.LOGIN_SUCCESS:
      state.isLoggingIn = false
      break
    case types.IS_JOINING_USER:
      state.isJoining = action.status
      break
    case types.REGISTER_PAGE_ERROR:
      state.registrationError = action.payload
      break
    case types.LOGIN_PAGE_ERROR:
      state.isLoggingIn = false
      break
    case types.CHECK_SSO:
      state.isLoggingIn = true
      state.isCheckingSSO = true
      break
    case types.CHECK_SSO_END:
      state.isLoggingIn = false
      state.isCheckingSSO = false
      state.isPasswordSupport = action.payload.isPasswordSupport
      state.isSSO = action.payload.isSSO
      break
    case types.CANCEL_CHECK_SSO:
      state.isLoggingIn = false
      state.isCheckingSSO = false
      state.isPasswordSupport = true
      state.isSSO = false
  }
}, initStatusState)
export interface AuthReducerState {
  authReducer: AuthState,
  authStatus: AuthStatusState
}
const auth = combineReducers({ authReducer, authStatus })
export default auth
