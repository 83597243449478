import config from '../envConfig'
import ApiBase from './ApiBase'

class SessionsApi extends ApiBase {
  // eslint-disable-next-line class-methods-use-this
  get resourcePath () {
    return '/sessions'
  }

  create = async (email, password) => {
    const body = { email, password }
    const response = await this.httpClient.post(this.resourcePath, body, undefined, this.defaultConfig)
    const {
      session_token: sessionToken,
      user_id: userId,
      primary_account_id: accountId,
      password_expires_at: passwordExpiresAt,
      password_change_required: passwordChangeRequired
    } = response.data
    return { sessionToken, userId, accountId, passwordExpiresAt, passwordChangeRequired }
  }

  verifySso = async (email, options = { }) => {
    const { cancelToken } = options
    const config = { ...this.defaultConfig, cancelToken }
    try {
      return await this.httpClient.post(`${this.resourcePath}/saml2/verify`, {}, { email }, config)
    } catch (err) {
      console.error(err)
    }
  }

  getSessionSso = async (sessionToken) => {
    const config = this.defaultConfig
    config.headers.Authorization = `Bearer ${sessionToken}`
    const response = await this.httpClient.get(`${this.resourcePath}/saml2`, {}, config)
    return response.data
  }

  getSsoConstraints = async (user) => {
    const config = this.defaultConfig
    const response = await this.httpClient.get(`${this.resourcePath}/${user}/constraints`, {}, config)
    return response.data
  }

  initSso = async (email, redirectPath = '/login') => {
    const myForm = document.createElement('FORM')
    myForm.name = 'myForm'
    myForm.method = 'POST'
    myForm.action = `${config.apiUrl}${this.resourcePath}/saml2`
    myForm.style.cssText = 'display:none;'

    const addInput = (name, value) => {
      const myTB = document.createElement('INPUT')
      myTB.type = 'TEXT'
      myTB.name = name
      myTB.value = value
      myForm.appendChild(myTB)
    }

    addInput('email', email)
    addInput('redirect_url', redirectPath)

    document.body.appendChild(myForm)
    myForm.submit()
  }

  kill = async () => this.httpClient.delete(this.resourcePath, { zooz_nobounce: true }, this.defaultConfig)
}

export default new SessionsApi()
