import { TranslationFunction } from '@zooz/react-localize'
import { global as globalTranslations, join as joinTranslations } from '@zooz/translation-values'

interface Errors {
  firstName: {
    required: (t: TranslationFunction) => string;
  };
  lastName: {
    required: (t: TranslationFunction) => string;
  };
  companyName: {
    required: (t: TranslationFunction) => string;
  };
  domain: {
    required: (t: TranslationFunction) => string;
    invalid: (t: TranslationFunction) => string;
  };
  email: {
    required: (t: TranslationFunction) => string;
    invalid: (t: TranslationFunction) => string;
    exists: (t: TranslationFunction) => string;
  };
  password: {
    required: (t: TranslationFunction) => string;
    tooShort: (t: TranslationFunction) => string;
    tooLong: (t: TranslationFunction) => string;
    currentPasswordIncorrect: (t: TranslationFunction) => string;
    identicalToLast: (t: TranslationFunction) => string;
    numberMissing: (t: TranslationFunction) => string;
    uppercaseMissing: (t: TranslationFunction) => string;
    lowercaseMissing: (t: TranslationFunction) => string;
    specialCharMissing: (t: TranslationFunction) => string;
    includesEmail: (t: TranslationFunction) => string;
  };
  confirmPassword: {
    required: (t: TranslationFunction) => string;
    invalid: (t: TranslationFunction) => string;
  };
  checkbox: {
    required: (t: TranslationFunction) => string;
  }
  invalidCredentials: (t: TranslationFunction) => string;
  invalidToken: (t: TranslationFunction) => string;
  defaultError: (t: TranslationFunction) => string;
  alreadyMember: (t: TranslationFunction) => string;
}

const errors: Errors = {

  // registerForm field errors
  firstName: {
    required: t => t(globalTranslations.sharedText_errors_firstNameRequired.key, {
      fallback: globalTranslations.sharedText_errors_firstNameRequired.fallback
    })
  },
  lastName: {
    required: t => t(globalTranslations.sharedText_errors_lastNameRequired.key, {
      fallback: globalTranslations.sharedText_errors_lastNameRequired.fallback
    })
  },
  companyName: {
    required: t => t(globalTranslations.sharedText_errors_companyNameRequired.key, {
      fallback: globalTranslations.sharedText_errors_companyNameRequired.fallback
    })
  },
  domain: {
    required: t => t(globalTranslations.sharedText_errors_domainNameRequired.key, {
      fallback: globalTranslations.sharedText_errors_domainNameRequired.fallback
    }),
    invalid: t => t(globalTranslations.sharedText_errors_domainNameInvalid.key, {
      fallback: globalTranslations.sharedText_errors_domainNameInvalid.fallback
    })
  },
  email: {
    required: t => t(globalTranslations.sharedText_errors_emailRequired.key, {
      fallback: globalTranslations.sharedText_errors_emailRequired.fallback
    }),
    invalid: t => t(globalTranslations.sharedText_errors_invalidEmail.key, {
      fallback: globalTranslations.sharedText_errors_invalidEmail.fallback
    }),
    exists: t => t(globalTranslations.sharedText_errors_alreadyRegistered.key, {
      fallback: globalTranslations.sharedText_errors_alreadyRegistered.fallback
    })
  },
  password: {
    required: t => t(globalTranslations.sharedText_errors_passwordRequired.key, {
      fallback: globalTranslations.sharedText_errors_passwordRequired.fallback
    }),
    tooShort: t => t(globalTranslations.sharedText_errors_passwordTooShort.key, {
      fallback: globalTranslations.sharedText_errors_passwordTooShort.fallback,
      minimumCharacters: 12
    }),
    tooLong: t => t(globalTranslations.sharedText_errors_passwordTooLong.key, {
      fallback: globalTranslations.sharedText_errors_passwordTooLong.fallback,
      maximumCharacters: 128
    }),
    currentPasswordIncorrect: t => t(globalTranslations.sharedText_errors_currentPasswordIncorrect.key, {
      fallback: globalTranslations.sharedText_errors_currentPasswordIncorrect.fallback
    }),
    identicalToLast: t => t(globalTranslations.sharedText_errors_identicalPasswords.key, {
      fallback: globalTranslations.sharedText_errors_identicalPasswords.fallback
    }),
    numberMissing: t => t(globalTranslations.sharedText_errors_includeNumber.key, {
      fallback: globalTranslations.sharedText_errors_includeNumber.fallback
    }),
    uppercaseMissing: t => t(globalTranslations.sharedText_errors_includeUppercase.key, {
      fallback: globalTranslations.sharedText_errors_includeUppercase.fallback
    }),
    lowercaseMissing: t => t(globalTranslations.sharedText_errors_includeLowercase.key, {
      fallback: globalTranslations.sharedText_errors_includeLowercase.fallback
    }),
    specialCharMissing: t => t(globalTranslations.sharedText_errors_includeSpecial.key, {
      fallback: globalTranslations.sharedText_errors_includeSpecial.fallback
    }),
    includesEmail: t => t(globalTranslations.sharedText_errors_includesEmail.key, {
      fallback: globalTranslations.sharedText_errors_includesEmail.fallback
    })
  },
  confirmPassword: {
    required: t => t(globalTranslations.sharedText_errors_confirmPassword.key, {
      fallback: globalTranslations.sharedText_errors_confirmPassword.fallback
    }),
    invalid: t => t(globalTranslations.sharedText_errors_passwordMatch.key, {
      fallback: globalTranslations.sharedText_errors_passwordMatch.fallback
    })
  },
  // other errors
  invalidCredentials: t => t(globalTranslations.sharedText_errors_doubleCheckEmailPassword.key, {
    fallback: globalTranslations.sharedText_errors_doubleCheckEmailPassword.fallback
  }),
  invalidToken: t => t(globalTranslations.sharedText_errors_tokenInvalid.key, {
    fallback: globalTranslations.sharedText_errors_tokenInvalid.fallback
  }),
  defaultError: t => t(globalTranslations.sharedText_errors_defaultError.key, {
    fallback: globalTranslations.sharedText_errors_defaultError.fallback
  }),
  checkbox: {
    required: t => t(joinTranslations.main_checkbox_error.key, {
      fallback: joinTranslations.main_checkbox_error.fallback
    })
  },
  alreadyMember: t => t(joinTranslations.main_errors_alreadyMember.key, {
    fallback: joinTranslations.main_errors_alreadyMember.fallback
  })
}

export default errors
