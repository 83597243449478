import React from 'react'
import { LocalizeProvider } from '@zooz/react-localize'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  Store,
  Reducer,
  ReducersMapObject,
  AnyAction
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import envConfig from '../envConfig'
import localize from '../features/Localize/localize'
import { reducer as localizationReducer } from '../features/Localize/redux/slice'
import sentry from '../helpers/sentry'
import * as authActionTypes from './auth/types'
import { authMiddleWare } from './middlewares'
import reducers, { AppReducersState } from './reducers'
import { registerAsyncSagas, registerDefaultSagas } from './sagas'
import GetUserConfirmation from '../features/App/components/GetUserConfirmation'
import Locations from '../helpers/tools/locations'
import authActions from './auth/actions'

const history = createBrowserHistory({
  getUserConfirmation (message, callback) {
    if (!message) {
      callback(true)
      return
    }

    const currentLocation = history.location
    const currentPath = currentLocation.pathname
    const PASSWORD_EXPIRY_PATH = Locations.passwordExpiry()

    ReactDOM.render(
      <LocalizeProvider localizeInstance={localize}>
        <GetUserConfirmation
          key={Date.now()}
          callback={(leave) => {
            if (leave && currentPath === PASSWORD_EXPIRY_PATH) {
              store.dispatch(authActions.unauthorized())
            }

            callback(leave)

            const modalElement = document.getElementById('user-confirmation-modal')
            if (modalElement) {
              ReactDOM.unmountComponentAtNode(modalElement)
            }
          }}
        />
      </LocalizeProvider>,
      document.getElementById('user-confirmation-modal')
    )
  },
  basename: envConfig.baseRouteName
})

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    sentry.captureException(error)
  }
})
const routeMiddleware = routerMiddleware(history)

const middlewares = [
  thunkMiddleware,
  sagaMiddleware,
  routeMiddleware,
  authMiddleWare
]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const defaultReducers = {
  ...reducers,
  router: connectRouter(history)
}

export interface PortalState extends AppReducersState {
  router: RouterState;
  [microAppReducerName: string]: unknown;
}

export interface AppStore extends Store<PortalState> {
  asyncReducers: Record<string, Reducer<unknown>>;
  asyncSagas: Record<string, unknown>;
  injectAsyncReducer: (name: string, asyncReducer: Reducer) => void;
  injectAsyncSaga: (name: string, registerFunction: () => void) => void;
}

// return state that should persist for as long as the app lives - not being reset on logout
const getPersistentState = (state: PortalState, action: AnyAction): Partial<PortalState> => ({
  localization: localizationReducer(state?.localization, action)
})

const getReducer = (combinedReducers = defaultReducers): Reducer<PortalState> => {
  const appReducer = combineReducers<PortalState>(combinedReducers as unknown as ReducersMapObject<PortalState>)

  return function (state: PortalState, action: AnyAction): PortalState {
    const persistentState = getPersistentState(state, action)
    if (action.type === authActionTypes.CLEAR_SESSION) {
      state = {
        ...persistentState
      } as PortalState
    }
    return appReducer(state, action)
  }
}

const store: AppStore = createStore(getReducer(), composeEnhancers(applyMiddleware(...middlewares)))
store.asyncReducers = {}
store.asyncSagas = {}

sagaMiddleware.run(registerDefaultSagas)

store.injectAsyncReducer = (name, asyncReducer): void => {
  store.asyncReducers[name] = asyncReducer
  store.replaceReducer(getReducer({ ...defaultReducers, ...store.asyncReducers }))
}

store.injectAsyncSaga = (name, registerFunction) => {
  if (!store.asyncSagas[name]) {
    store.asyncSagas[name] = registerFunction
    sagaMiddleware.run(registerAsyncSagas, [registerFunction])
  }
}

export { store, history }
