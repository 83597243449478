import React, { useState } from 'react'
import { FontAwesome, InputTitled } from '@zooz/generic-ui-components'

import FormItem from '../FormItem'

import styles from './InputField.scss'

interface InputFieldProps {
  autoFocus?: boolean,
  type?: string,
  label?: string,
  error?: string,
  isError?: boolean,
  placeholder?: string,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  disabled?: boolean,
  isEdit?: boolean,
  value?: string,
  id?: string
  autoComplete?: string
  showTogglePassword?: boolean
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  error,
  autoFocus,
  onChange,
  type,
  placeholder,
  id,
  onBlur,
  onFocus,
  onPressEnter,
  disabled,
  isEdit,
  value,
  autoComplete,
  showTogglePassword
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const finalType = showTogglePassword
    ? (isPasswordVisible ? 'text' : 'password')
    : type

  function handleKeyPress (event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter' && onPressEnter) {
      onPressEnter(event)
    }
  }

  return (
    <FormItem>
      <div className={styles.itemWrapper}>
        <InputTitled
          className={styles.inputLabel}
          title={label}
          errorText={error}
          autoFocus={autoFocus}
          onChange={onChange}
          type={finalType}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          isEdit={isEdit}
          onKeyPress={handleKeyPress}
          autoComplete={autoComplete}
        />

        {showTogglePassword && value && (
          <FontAwesome
            className={styles.eyeIcon}
            icon={isPasswordVisible ? 'eye-slash' : 'eye'}
            onClick={() => setIsPasswordVisible(prev => !prev)}
            aria-label='Toggle password visibility'
          />
        )}
      </div>
    </FormItem>
  )
}

export default InputField
