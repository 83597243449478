import { TranslationFunction } from '@zooz/react-localize'
import { ZxcvbnFactory } from '@zxcvbn-ts/core'
import merge from 'lodash/merge'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import {
  lowercaseMatcher,
  MatcherNames,
  maxLengthMatcher,
  minLengthMatcher,
  numberMatcher,
  specialMatcher,
  uppercaseMatcher,
  MatchersTranslations
} from 'zxcvbn-custom-matchers'

import errors from '../constants/Errors'

export const REQUIRED_PASSWORD_STRENGTH_SCORE = Number(process.env.REQUIRED_PASSWORD_STRENGTH_SCORE || 3)
export const PASSWORD_MIN_LENGTH = Number(process.env.PASSWORD_MIN_LENGTH || 12)
export const PASSWORD_MAX_LENGTH = Number(process.env.PASSWORD_MAX_LENGTH || 128)

const options = {
  dictionary: merge({}, zxcvbnCommonPackage.dictionary, zxcvbnEnPackage.dictionary),
  translations: merge({}, zxcvbnEnPackage.translations, MatchersTranslations)
}

const zxcvbnMatchers = {
  [MatcherNames.maxLength]: maxLengthMatcher(PASSWORD_MAX_LENGTH),
  [MatcherNames.minLength]: minLengthMatcher(PASSWORD_MIN_LENGTH),
  [MatcherNames.number]: numberMatcher,
  [MatcherNames.lowercase]: lowercaseMatcher,
  [MatcherNames.uppercase]: uppercaseMatcher,
  [MatcherNames.specialChar]: specialMatcher
}

const zxcvbn = new ZxcvbnFactory(options, zxcvbnMatchers)

interface PasswordValidationResult {
  valid: boolean;
  strengthScore: number;
  message?: (t: TranslationFunction) => string;
}

export default (
  password: string,
  userInputs: { email?: string; currentPassword?: string } = {}
): PasswordValidationResult => {
  if (!password || password.length === 0) {
    return {
      valid: false,
      message: errors.password.required,
      strengthScore: 0
    }
  }
  if (password.length < PASSWORD_MIN_LENGTH) {
    return {
      valid: false,
      message: errors.password.tooShort,
      strengthScore: 0
    }
  }

  if (password.length > PASSWORD_MAX_LENGTH) {
    return {
      valid: false,
      message: errors.password.tooLong,
      strengthScore: 0
    }
  }

  if (userInputs.email && password.includes(userInputs.email)) {
    return {
      valid: false,
      message: errors.password.includesEmail,
      strengthScore: 0
    }
  }

  if (userInputs.currentPassword && password === userInputs.currentPassword) {
    return {
      valid: false,
      message: errors.password.identicalToLast,
      strengthScore: 0
    }
  }

  const result = zxcvbn.check(password)
  const { score, feedback } = result

  if (score < REQUIRED_PASSWORD_STRENGTH_SCORE || feedback.warning) {
    const error = feedback.warning || feedback.suggestions.at(-1)
    if (error) {
      return {
        valid: false,
        message: () => error,
        strengthScore: score
      }
    }
  }

  return { valid: true, strengthScore: result.score }
}
