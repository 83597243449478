import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as authSelectors from './redux/auth/selectors'
import Locations from './helpers/tools/locations'
import { shouldRedirectToPasswordExpiry } from './helpers/passwordExpiry'

export const PublicRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn)

  return (
    <Route
      {...rest}
      render={props => (
        isLoggedIn
          ? <Redirect to={Locations.root()} />
          : <Component {...props} />
      )}
    />
  )
}

export const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn)
  const user = useSelector(authSelectors.currentUser)
  const { passwordChangeRequired, passwordExpiresAt } = user.get()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: Locations.login(), state: { from: props.location } }} />
        }
        if (shouldRedirectToPasswordExpiry({ passwordExpiresAt, passwordChangeRequired }) &&
          props.location.pathname !== Locations.passwordExpiry()) {
          return <Redirect to={Locations.passwordExpiry()} />
        }
        return <Component {...props} />
      }}
    />
  )
}
