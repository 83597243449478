import { Managment, UserConfiguration } from '@payu/paymentsos-types'
import { call, put, takeEvery, select } from 'redux-saga/effects'

import UsersApi from '../../../api/UsersApi'
import Errors from '../../../constants/Errors'
import { getUserConfiguration } from '../../../redux/userConfiguration/selectors'
import { getLocalizationPreferences } from '../../Localize/helpers'
import localize from '../../Localize/localize'
import { getNumberFormatByProperties } from '../helpers/localizationMapping'
import LocalizationPreviewSingleton from '../helpers/localizationPreview'
import translations from '../translations'
import {
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  INIT_LOCALIZATION_INSTANCE,
  SET_LOCALIZATION_PREFERENCES,
  getProfileSuccess,
  cleanTransientPasswords,
  UpdateProfileAction,
  UpdatePasswordAction,
  SetLocalizationPreferencesAction,
  setLocalizationPreferencesSucceed
} from './actions'
import * as selectors from './selectors'
import messageDispatcher from '../../Messages/redux/messageDispatcher'
import { APIError } from '../../../api/ApiBase'
import { SAME_PASSWORD_ERROR } from '../../../constants/common'

const WRONG_PASSWORD_ERROR = 'Provided password does not match user\'s password.'

function * getProfile () {
  try {
    const profile: Managment.UserResource = yield call(UsersApi.getProfile)
    yield put(getProfileSuccess(profile))
  } catch (err) {
    messageDispatcher.addError({
      code: 'default',
      text: Errors.defaultError(localize.translate)
    })
    console.log(err)
  }
}

function * updateProfile (action: UpdateProfileAction) {
  try {
    const isPasswordFilled: boolean = yield select(selectors.isPasswordFilled)
    yield call(UsersApi.updateProfile, action.profile)

    if (!isPasswordFilled) {
      messageDispatcher.addSuccess(
        translations.detailsUpdatedSuccessfully(localize.translate)
      )
    }
  } catch (err) {
    messageDispatcher.addError({
      code: 'default',
      text: Errors.defaultError(localize.translate)
    })
  }
}

function * updatePassword (action: UpdatePasswordAction) {
  try {
    const isPasswordFilled: boolean = yield select(selectors.isPasswordFilled)

    if (isPasswordFilled) {
      yield call(UsersApi.updatePassword, action.passwords)
      messageDispatcher.addSuccess(
        translations.detailsUpdatedSuccessfully(localize.translate)
      )
      yield put(cleanTransientPasswords())
    }
  } catch (err) {
    const errorMessage = (err as APIError)?.response?.data?.more_info
    const errorMapping: Record<string, string> = {
      [WRONG_PASSWORD_ERROR]: translations.wrongPassword(localize.translate),
      [SAME_PASSWORD_ERROR]: Errors.password.identicalToLast(localize.translate)
    }
    const errorText = errorMapping[errorMessage] || Errors.defaultError(localize.translate)

    messageDispatcher.addError({
      code: 'default',
      text: errorText
    })
  }
}

function * initPreviewLocalizationInstance () {
  try {
    const localizationPreview = LocalizationPreviewSingleton.getInstance()

    if (!localizationPreview.isInitialized()) {
      yield call(localizationPreview.init.bind(localizationPreview))

      // Get user localization preferences stored in UserConfiguration service
      const userConfig: UserConfiguration.UserConfiguration = yield select(getUserConfiguration)

      const localizationPreferences = getLocalizationPreferences(localizationPreview, userConfig)

      localizationPreview.changeShortDateFormat(localizationPreferences.dateFormat)
      localizationPreview.changeNumberFormat({
        decimalNumberFormat: localizationPreferences.numberFormat.decimal,
        groupNumberDelimiter: localizationPreferences.numberFormat.group
      })

      yield put(setLocalizationPreferencesSucceed({
        language: localizationPreferences.language,
        dateFormat: localizationPreferences.dateFormat,
        numberFormat: getNumberFormatByProperties(localizationPreferences.numberFormat)
      }))
    }
  } catch (error) {
    // silently report initialization error
    console.error('Failed to init localization instance for user profile preferences preview')
  }
}

function * setPreviewLocalizationPreferences (action: SetLocalizationPreferencesAction) {
  const localizationPreview = LocalizationPreviewSingleton.getInstance()

  try {
    if (localizationPreview.isInitialized() && localizationPreview.isReady()) {
      // @ts-ignore: ts fails to set types correctly
      yield call({ context: localizationPreview, fn: localizationPreview.changeLanguage }, action.language)

      const localizationPreferences = getLocalizationPreferences(localizationPreview)

      localizationPreview.changeShortDateFormat(localizationPreferences.dateFormat)
      localizationPreview.changeNumberFormat({
        decimalNumberFormat: localizationPreferences.numberFormat.decimal,
        groupNumberDelimiter: localizationPreferences.numberFormat.group
      })

      yield put(setLocalizationPreferencesSucceed({
        language: localizationPreferences.language,
        dateFormat: localizationPreferences.dateFormat,
        numberFormat: getNumberFormatByProperties(localizationPreferences.numberFormat)
      }))
    }
  } catch (error) {
    // silently report initialization error
    console.error('Failed to set localization preferences for user profile preferences preview')
  }
}

export default function * userProfileSaga () {
  yield takeEvery(GET_PROFILE, getProfile)
  yield takeEvery(UPDATE_PROFILE, updateProfile)
  yield takeEvery(UPDATE_PASSWORD, updatePassword)
  yield takeEvery(INIT_LOCALIZATION_INSTANCE, initPreviewLocalizationInstance)
  yield takeEvery(SET_LOCALIZATION_PREFERENCES, setPreviewLocalizationPreferences)
}
